import React from 'react';
import Layout, { Content } from '../components/Layout';
import SoftApplication from '../components/forms/SoftApplication';

const BookNow = ({ pageContext, location }) => {
  return (
    <Layout title={'Book Now'}>
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <SoftApplication />
      </Content>
    </Layout>
  );
};

export default BookNow;